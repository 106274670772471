<template>
  <NotFound v-if="residencyNotFound" />
  <div v-else-if="residencyBySlug" class="container px-4 py-12 md:py-16 mx-auto">
    <p class="text-fg-disclaimer">Residency at {{ venue_name }}</p>
    <h1 class="text-4xl font-semibold font-heading">
      {{ residencyBySlug.name }}
    </h1>
    <p class="text-secondary text-lg">
      <span>{{ settingStore.fullDate(residencyBySlug.start_date) }}</span>
      <span v-if="residencyBySlug.end_date">
        - {{ settingStore.fullDate(residencyBySlug.end_date) }}
      </span>
    </p>

    <div class="mt-6 flex flex-col-reverse gap-8 lg:flex-row">
      <div v-if="show" class="flex flex-col gap-2 lg:w-3/5">
        <!--SHOW IMAGE-->
        <div class="w-full">
          <picture id="residency-image">
            <source :srcset="show.srcset.rectSm.src" type="image/webp" media="(max-width: 768px)" />
            <source :srcset="show.srcset.rectXl.src" type="image/webp" />
            <img :src="show.srcset.rectXl.src" :alt="show.name" class="w-full h-auto" />
          </picture>
        </div>

        <!--SHOW DESCRIPTION-->
        <p id="residency-description" class="whitespace-pre-line">
          {{ residencyBySlug.description || show.description }}
        </p>
      </div>
      <div class="flex flex-col gap-4 lg:grow">
        <!--PERFORMANCE LIST-->
        <div>
          <div
            class="pb-4 border-b border-linear-g-primary text-lg flex justify-between gap-2 items-center font-semibold font-heading"
          >
            <h2>Shows</h2>
            <h2>Buy Tickets</h2>
          </div>
          <template v-if="residencyShows.length > 0">
            <div
              v-for="show in residencyShows"
              :key="show.id"
              :id="`show-${show.id}-date-${show.date}`"
              class="py-4 border-b border-linear-g-primary flex flex-col gap-1 items-start justify-start sm:items-center sm:justify-between sm:flex-row sm:gap-4"
            >
              <div class="show-header">
                <p class="text-sm line-clamp-1">{{ show.name }}</p>
                <p>{{ settingStore.mediumDate(show.datetime) }}</p>
              </div>
              <div
                class="flex flex-col gap-1 justify-start items-start w-max sm:gap-4 sm:items-center sm:flex-row"
              >
                <PerformanceButton
                  v-for="performance in show.performances"
                  :key="performance.id"
                  :performance="performance"
                  :error="error"
                  :id="`performance-${performance.id}`"
                  :forcePurchase="Boolean(inviteCode)"
                  buttonClasses="performance-link !px-2 !text-button-guest-primary disabled:!text-disabled !bg-transparent w-max !text-base !font-normal"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <p>No upcoming shows</p>
          </template>
        </div>

        <!--SHARING LINK-->
        <div>
          <p class="text-sm">Share this page</p>
          <p id="residency-link" class="cursor-pointer text-secondary" @click="() => copy()">
            {{ copied ? 'Copied!' : residencyPublicLink }}
            <ClipboardDocument class="w-5 h-5 inline" />
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="container px-4 py-12 md:py-16 mx-auto">
    <p>Loading Residency...</p>
  </div>
</template>

<script lang="ts" setup>
import { useClipboard } from '@vueuse/core'
import { isEmpty } from 'lodash-es'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, onMounted, onServerPrefetch, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'

import ClipboardDocument from '@/assets/clipboardDocument.svg?component'
import PerformanceButton from '@/modules/guestUi/components/organisms/PerformanceButton.vue'
import { DisplayShow } from '@/modules/guestUi/interfaces/shows'
import NotFound from '@/modules/guestUi/pages/NotFound.vue'
import { usePerformancePaginate } from '@/modules/guestUi/stores/PerformancePaginateStore'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { ErrorData, blankError } from '@/utils/errors'
import { sortBy, todayIsoStr } from '@/utils/utils'

import { ResidencyRead, ShowRead } from '@generated/types'

const error = ref<ErrorData>(blankError())
const checkoutStore = useCheckoutStore()
const settingStore = useSettingStore()
const { venue_name } = storeToRefs(settingStore)

const props = defineProps<{ slug: string }>()
const { slug } = toRefs(props)
const residencyBySlug = ref<ResidencyRead | null>(null)
const residencyNotFound = ref(false)

const route = useRoute()
const inviteCode = computed<string | undefined>(() => route.query.invite as string | undefined)
onMounted(() => {
  checkoutStore.setInviteCode(inviteCode.value)
})

const performancePaginateStore = usePerformancePaginate()
async function load() {
  try {
    residencyNotFound.value = false
    residencyBySlug.value = await checkoutStore.getResidency(slug.value)
    if (!residencyBySlug.value || isEmpty(residencyBySlug.value)) {
      residencyNotFound.value = true
      return
    }
    await performancePaginateStore.getPerformances({ booking: 'true' })
  } catch (e) {
    residencyNotFound.value = true
  }
}

onServerPrefetch(async () => {
  await load()
  if (residencyNotFound.value) return
  settingStore.ssrTitle = residencyBySlug.value?.name || ''
  settingStore.ssrDescription =
    residencyBySlug.value?.description || residencyBySlug.value?.show.description || ''
  settingStore.ssrImage = residencyBySlug.value?.show.srcset.rectLg.src || ''
})
onBeforeMount(load)

const residencyPublicLink = computed<string>(function () {
  if (!residencyBySlug.value) return ''
  return `${settingStore.siteDomain}/r/${residencyBySlug.value.slug}`
})
const { copy, copied } = useClipboard({ source: residencyPublicLink })
const show = computed<ShowRead | undefined>(() => residencyBySlug.value?.show)

const residencyShows = computed<DisplayShow[]>(function () {
  if (!residencyBySlug.value) return []

  const shows: DisplayShow[] = []
  const current = todayIsoStr()
  for (const performance of residencyBySlug.value!.performances) {
    if (!performancePaginateStore.performances.find(p => p.id === performance.id)) continue

    const performanceDate = settingStore.dateIsoStr(performance.datetime)
    if (performanceDate < current) continue
    const show = shows.find(show => show.date === performanceDate && show.id == performance.show_id)

    if (show) {
      show.performances.push(performance)
      sortBy(show.performances, 'datetime')
    } else {
      const desc = performance.show?.description || ''
      shows.push({
        ...performance.show,
        id: performance.show_id,
        date: performanceDate,
        datetime: performance.datetime,
        dateStr: settingStore.mediumDate(performance.datetime),
        description: desc,
        position: performance.show?.position || [],
        performances: [performance],
      })
    }
  }

  return sortBy(shows, 'datetime')
})
</script>
