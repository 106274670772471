import { EventBusKey, useEventBus } from '@vueuse/core'

import {
  GuestRead,
  ReportInstanceRead,
  ReservationDetailedRead,
  StripeReaderId,
  StripeReaderMessageRead,
} from '@generated/types'

export interface SimulatedEvent {
  amount: number
  reader_id: StripeReaderId
}
export const readerKey: EventBusKey<StripeReaderMessageRead> = Symbol('reader-bus')
export const simulatedKey: EventBusKey<SimulatedEvent> = Symbol('simulated-bus')
export const reservationKey: EventBusKey<ReservationDetailedRead> = Symbol('reservation-bus')
export const guestKey: EventBusKey<GuestRead> = Symbol('guest-bus')
export const reportInstanceKey: EventBusKey<ReportInstanceRead> = Symbol('report-instance-bus')

export const performanceTableKey: EventBusKey<ReservationDetailedRead> =
  Symbol('performance-table-bus')

export function onVenueMessage(ws: WebSocket | null, event: MessageEvent) {
  const data = JSON.parse(event.data)
  if (data.type === 'reader_result') {
    useEventBus(readerKey).emit(data.message)
  } else if (data.type === 'reader_send') {
    useEventBus(simulatedKey).emit(data.message)
  } else if (data.type === 'reservation_change') {
    useEventBus(reservationKey).emit(data.message)
  } else if (data.type === 'guest_change') {
    useEventBus(guestKey).emit(data.message)
  } else if (data.type === 'report_instance_change') {
    useEventBus(reportInstanceKey).emit(data.message)
  }
}
