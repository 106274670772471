<template>
  <section class="w-full lg:container lg:mx-auto" ref="showList">
    <PerformanceList
      :performances="performancePaginateStore.performances"
      :idsToForcePurchase="[performanceId]"
    />
    <Spinner class="!h-24" :display="loading" />
  </section>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'

import Spinner from '@/common/components/atoms/Spinner.vue'
import PerformanceList from '@/modules/guestUi/components/organisms/PerformanceList.vue'
import { usePerformancePaginate } from '@/modules/guestUi/stores/PerformancePaginateStore'
import { useCheckoutStore } from '@/stores/checkout'

const loading = ref<boolean>(true)
const props = withDefaults(defineProps<{ performanceId?: number }>(), {
  performanceId: undefined,
})
const { performanceId } = toRefs(props)

const checkoutStore = useCheckoutStore()
const performancePaginateStore = usePerformancePaginate()
const route = useRoute()
const inviteCode = computed<string | undefined>(() => route.query.invite as string | undefined)

onMounted(async () => {
  await Promise.all([checkoutStore.abandonAnonymousReservation(), fetchPerformance()])
  checkoutStore.clearAll()
  checkoutStore.setInviteCode(inviteCode.value)
  loading.value = false
})
async function fetchPerformance() {
  if (!performanceId.value || !inviteCode.value) return
  await performancePaginateStore.getPerformanceWithInvite(performanceId.value, inviteCode.value)
}
</script>
