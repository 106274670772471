<template>
  <nav
    class="z-10 w-full bg-surface-base px-4 border-linear-bg-subtle border-b flex justify-start items-center"
  >
    <TurntableLogo />
    <Divider layout="vertical" class="self-stretch" />
    <VenueSwitchMenu />
    <div
      id="header-title"
      class="font-heading text-xl font-semibold uppercase mx-auto hidden md:block"
    >
      {{ pageTitle }}
    </div>
    <div class="flex self-stretch text-left mr-4 py-1 ml-auto md:ml-0">
      <OnClickOutside @trigger="closeNav" class="self-center relative z-20">
        <div
          @click="ui.navOpen = !ui.navOpen"
          class="flex items-center justify-center py-6 cursor-pointer"
          id="header-toggle"
        >
          <ActionMenuIcon />
        </div>
        <div
          v-show="ui.navOpen"
          class="absolute right-0 z-10 origin-top-right rounded-md bg-surface-primary shadow-lg -translate-y-2"
          id="header-menu"
        >
          <VenueThemeSwitch v-if="isOnboardingFlow" class="my-2 mx-4 w-48" />
          <div v-else class="flex gap-4 p-8">
            <div
              v-if="searchResults.length"
              id="search-results"
              :class="{ 'w-700px mr-12': searchResults.length }"
            >
              <div
                v-for="result in searchResults"
                :key="`result-${result.id}`"
                class="search-result mb-5 p-2 hover:bg-surface-base hover:rounded"
                @click="closeNav"
              >
                <SearchResult :result="result" />
              </div>
            </div>
            <div class="flex flex-col gap-4" @click="closeNav">
              <template v-if="!isSettingViewOnly">
                <a
                  href="/"
                  id="venue-show-list"
                  class="header-link text-fg-link-primary block text-base font-normal"
                >
                  Public Show List
                </a>
                <div class="w-full h-0.5 border-b-linear-subtle border-b-2" />
              </template>
              <div class="flex-col flex gap-2">
                <PerformanceToolsLink
                  v-if="!isSettingViewOnly"
                  id="venue-performance-tools"
                  class="header-link text-fg-link-primary block text-base font-normal"
                >
                  Performance Tools
                </PerformanceToolsLink>
                <router-link
                  v-if="!isSettingViewOnly"
                  :to="{ name: RESIDENCY_ROUTE_NAMES.LIST }"
                  id="venue-residencies"
                  class="header-link text-fg-link-primary block text-base font-normal"
                >
                  Residencies
                </router-link>
                <router-link
                  v-if="!isSettingViewOnly"
                  :to="{ name: SHOW_ROUTE_NAMES.LIST }"
                  id="venue-shows"
                  class="header-link text-fg-link-primary block text-base font-normal"
                  >Shows</router-link
                >
                <router-link
                  v-if="!isSettingViewOnly"
                  :to="{ name: FLOOR_PLAN_ROUTE_NAMES.LIST }"
                  id="venue-floorplans"
                  class="header-link text-fg-link-primary block text-base font-normal"
                  >Floor Plans</router-link
                >
                <router-link
                  v-if="!isSettingViewOnly"
                  :to="{ name: 'reservationsDashboard' }"
                  id="venue-dashboard"
                  class="header-link text-fg-link-primary block text-base font-normal"
                  >Dashboard</router-link
                >
                <template v-if="isOwner">
                  <router-link
                    v-if="!isSettingViewOnly"
                    :to="{ name: 'reportsOverview' }"
                    id="venue-reports"
                    class="header-link text-fg-link-primary block text-base font-normal"
                    >Reports</router-link
                  >

                  <router-link
                    :to="{ name: SETTING_ROUTE_NAMES.FEES }"
                    id="setting-expose-fee"
                    class="header-link text-fg-link-primary block text-base font-normal"
                    >Settings</router-link
                  >

                  <router-link
                    v-if="!isSettingViewOnly"
                    :to="{ name: MAILCHIMP_ROUTE_NAMES.TEMPLATE_EDIT }"
                    id="mailchimp-template-edit"
                    class="header-link text-fg-link-primary block text-base font-normal"
                    >Marketing</router-link
                  >
                </template>
              </div>
              <SearchInput v-if="!isSettingViewOnly" ref="searchInput" @click.stop />
              <div class="w-full h-0.5 border-b-linear-subtle border-b-2" />
              <VenueThemeSwitch class="w-48" />
              <div class="w-full h-0.5 border-b-linear-subtle border-b-2" />
              <div class="flex flex-col gap-2">
                <a
                  href="https://support.turntabletickets.com/knowledge/"
                  target="_blank"
                  id="venue-help-center"
                  class="header-link text-fg-link-primary block text-base font-normal"
                >
                  Help Center
                </a>
                <form method="post" action="/accounts/logout/" id="logout-form">
                  <input type="hidden" name="csrfmiddlewaretoken" :value="csrfToken" />
                  <span
                    id="venue-logout"
                    class="header-link text-fg-link-primary block text-base font-normal cursor-pointer"
                    @click="logout"
                    >Logout</span
                  >
                </form>
              </div>
            </div>
          </div>
        </div>
      </OnClickOutside>
    </div>
    <div v-if="me" id="header-user-icon" class="text-sm">
      {{ me.first_name }}
    </div>
  </nav>
</template>
<script lang="ts">
import { OnClickOutside } from '@vueuse/components'
import { mapState, mapStores } from 'pinia'
import Divider from 'primevue/divider'
import { defineComponent } from 'vue'

import ActionMenuIcon from '@/assets/actionMenu.svg?component'
import TurntableLogo from '@/assets/turntableLogo.svg?component'
import SearchInput from '@/components/SearchInput.vue'
import SearchResult from '@/components/SearchResult.vue'
import { FLOOR_PLAN_ROUTE_NAMES } from '@/modules/floorplan/constants/FloorPlanRouteNames'
import { MAILCHIMP_ROUTE_NAMES } from '@/modules/mailchimp/constants/MailchimpRouteNames'
import { ONBOARDING_ROUTE_NAMES } from '@/modules/onboarding/constants/OnboardingRouteNames'
import PerformanceToolsLink from '@/modules/performance/components/atoms/PerformanceToolsLink.vue'
import { PERFORMANCE_ROUTE_NAMES } from '@/modules/performance/constants/PerformanceRouteConstants'
import { usePerformanceStore } from '@/modules/performance/stores/PerformanceStore'
import { RESIDENCY_ROUTE_NAMES } from '@/modules/residency/constants/ResidencyRouteNames'
import { SETTING_ROUTE_NAMES } from '@/modules/setting/constants/SettingRouteNames'
import { SHOW_ROUTE_NAMES } from '@/modules/show/constants/ShowRouteName'
import VenueSwitchMenu from '@/modules/venueUi/components/molecules/VenueSwitchMenu.vue'
import VenueThemeSwitch from '@/modules/venueUi/components/molecules/VenueThemeSwitch.vue'
import { useGuestStore } from '@/stores/guest'
import { useSettingStore } from '@/stores/setting'
import { useUserStore } from '@/stores/user'
import { readCookie, sortBy } from '@/utils/utils'

import { GuestSearchRead } from '@generated/types'

export default defineComponent({
  name: 'HeaderComponent',
  components: {
    TurntableLogo,
    Divider,
    VenueThemeSwitch,
    PerformanceToolsLink,
    VenueSwitchMenu,
    OnClickOutside,
    SearchInput,
    SearchResult,
    ActionMenuIcon,
  },
  data() {
    return {
      ui: {
        navOpen: false,
      },
    }
  },
  async mounted() {
    await this.settingStore.loading
  },
  computed: {
    SHOW_ROUTE_NAMES() {
      return SHOW_ROUTE_NAMES
    },
    RESIDENCY_ROUTE_NAMES() {
      return RESIDENCY_ROUTE_NAMES
    },
    SETTING_ROUTE_NAMES() {
      return SETTING_ROUTE_NAMES
    },
    FLOOR_PLAN_ROUTE_NAMES() {
      return FLOOR_PLAN_ROUTE_NAMES
    },
    PERFORMANCE_ROUTE_NAMES() {
      return PERFORMANCE_ROUTE_NAMES
    },
    MAILCHIMP_ROUTE_NAMES() {
      return MAILCHIMP_ROUTE_NAMES
    },
    ...mapStores(useGuestStore, useSettingStore, usePerformanceStore),
    ...mapState(useUserStore, ['me', 'isLoggedIn', 'isOwner', 'initials']),
    ...mapState(useSettingStore, ['isSettingViewOnly']),

    pageTitle(): string {
      return (this.$route.meta?.pageTitle as string) || (this.$route.name as string) || ''
    },
    searchResults(): GuestSearchRead[] {
      sortBy(this.guestStore.searchResults, 'last_name')
      return this.guestStore.searchResults
    },
    isOnboardingFlow(): boolean {
      return Object.values(ONBOARDING_ROUTE_NAMES).includes(this.$route.name as string)
    },
    csrfToken() {
      return readCookie('csrftoken')
    },
  },
  methods: {
    closeNav(): void {
      this.ui.navOpen ? (this.ui.navOpen = false) : null
    },
    logout(): void {
      document.getElementById('logout-form')?.submit()
    },
  },
  watch: {
    $route() {
      this.ui.navOpen = false
    },
    'ui.navOpen': function (val) {
      if (val || !this.$refs.searchInput) return
      const searchInput = this.$refs.searchInput as typeof SearchInput
      searchInput.clear()
    },
  },
})
</script>
