<template>
  <section
    class="min-h-screen bg-surface-base text-fg-primary text-fg-g-primary print:!bg-transparent print:!text-black"
  >
    <MessageAlert id="venue-version-alert" :show="isVenueUi && hasVenueAppVersionChanged">
      There's a new version of TurnTable available.
      <a id="refresh-button" class="text-fg-link-primary cursor-pointer" @click="refresh">
        Refresh to get the latest.
      </a>
    </MessageAlert>
    <HeaderComponent class="print:hidden" v-show="!isCheckoutDesktopFlow" />
    <div
      class="w-full"
      :class="[isCheckoutDesktopFlow ? 'flex justify-between items-start' : 'px-4 lg:px-16 py-4']"
    >
      <StatusBar v-show="!isCheckoutDesktopFlow" />
      <router-view :class="isCheckoutDesktopFlow ? 'py-16 px-12 w-full shrink-[20]' : ''" />
      <CheckoutDesktopDetailSidebar v-if="isCheckoutDesktopFlow && !isOnboardingFlow" />
    </div>
  </section>
</template>

<script lang="ts">
import { venueRoutes } from './router/venue-routes'
import { breakpointsTailwind, useBreakpoints, useEventBus, useWebSocket } from '@vueuse/core'
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

import { guestKey, onVenueMessage } from '@/api/events'
import MessageAlert from '@/common/components/molecules/MessageAlert.vue'
import CheckoutDesktopDetailSidebar from '@/modules/guestUi/components/organisms/CheckoutDesktopDetailSidebar.vue'
import StatusBar from '@/modules/guestUi/components/organisms/StatusBar.vue'
import { usePerformanceReservationStore } from '@/modules/performance/stores/PerformanceReservationStore'
import HeaderComponent from '@/modules/venueUi/components/organisms/HeaderComponent.vue'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import loc from '@/utils/loc'

export default defineComponent({
  name: 'AppVenue',
  components: {
    CheckoutDesktopDetailSidebar,
    MessageAlert,
    HeaderComponent,
    StatusBar,
  },

  computed: {
    ...mapStores(useSettingStore, useCheckoutStore),
    hasVenueAppVersionChanged(): boolean {
      return this.settingStore.hasVenueAppVersionChanged
    },
    isVenueUi(): boolean {
      return !this.$route.meta.guest
    },
    isLargeScreen(): boolean {
      return useBreakpoints(breakpointsTailwind).lg.value
    },
    isOnboardingFlow(): boolean {
      return !!this.$route.meta?.onboarding
    },
    isCheckoutDesktopFlow(): boolean {
      return (
        this.checkoutStore.performance !== null &&
        this.isLargeScreen &&
        this.$route.name !== 'setShow' &&
        this.$route.name !== 'showDetails' &&
        this.$route.name !== 'calendar' &&
        this.$route.name !== 'order-details' &&
        this.$route.name !== 'residency-hub' &&
        !this.$route.path.startsWith('/venue')
      )
    },
  },
  setup() {
    //@ts-ignore
    venueRoutes[0].component() // force load the venue chunk
    useWebSocket(`wss://${window.location.host}/ws/`, {
      autoReconnect: {
        retries: 5,
        delay: 1000,
      },
      onMessage: onVenueMessage,
    })
    useEventBus(guestKey).on(usePerformanceReservationStore().onGuestChange)
  },
  methods: {
    refresh() {
      loc.reload()
    },
    updateTheme(): void {
      document.body.setAttribute('class', 'venue-ui')
    },
  },
  mounted() {
    if (document) {
      const hsScript = document.createElement('script')
      hsScript.src = '//js.hs-scripts.com/22692115.js'
      document.head.appendChild(hsScript)
    }
    this.updateTheme()
  },
  watch: {
    '$route.path'(value: string) {
      if (value.startsWith('/venue')) {
        this.checkoutStore.performance = null
      }
      this.updateTheme()
    },
    isCheckoutDesktopFlow: {
      immediate: true,
      handler(value: boolean) {
        this.checkoutStore.setIsDesktopFlow(value)
      },
    },
  },
})
</script>
