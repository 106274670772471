<template>
  <section>
    <div class="contents">
      <router-link :to="{ name: 'guest', params: { id: guest.id } }">
        <div class="font-heading font-semibold text-xl text-fg-link-primary">
          {{ guest.last_name }}, {{ guest.first_name }}<br />
        </div>
        <div class="flex flex-row flex-wrap text-xs font-sans text-fg-link-primary">
          <div class="w-5/12 underline break-words pr-16">{{ guest.email }}</div>
          <div class="w-4/12">{{ guest.phone }}</div>
          <div class="w-3/12 text-right">
            {{ guest.transaction_count }} Reservation<span v-if="guest.transaction_count > 1"
              >s</span
            >
          </div>
        </div>
      </router-link>
    </div>
  </section>
</template>
<style scoped></style>
<script lang="ts">
import { PropType, defineComponent } from 'vue'

import { GuestSearchRead } from '@generated/types'

export default defineComponent({
  name: 'SearchResult',
  props: {
    result: {
      type: Object as PropType<GuestSearchRead>,
      required: true,
    },
  },
  computed: {
    guest(): GuestSearchRead {
      return this.result
    },
  },
})
</script>
