<template>
  <router-link :to="performanceRouteLink" id="performance-tools-link">
    <slot />
  </router-link>
</template>

<script setup lang="ts">
import * as _ from 'lodash-es'
import { computed } from 'vue'
import { RouteLocationRaw, useRoute } from 'vue-router'

import { StorePerformance } from '@/models'
import { PERFORMANCE_ROUTE_NAMES } from '@/modules/performance/constants/PerformanceRouteConstants'
import { PERFORMANCE_PARAMS } from '@/modules/performance/stores/PerformanceReservationStore'
import { usePerformanceStore } from '@/modules/performance/stores/PerformanceStore'
import { todayIsoStr } from '@/utils/utils'

const performanceStore = usePerformanceStore()
const route = useRoute()
const performanceRouteLink = computed<RouteLocationRaw>(function () {
  const performanceId = route.params.performanceId
  const reservationId = route.params.reservationId
  const routeName = route.name

  const performanceList = _.filter(performanceStore.performances, { date: todayIsoStr() })
  const performanceOfToday = _.head(
    _.sortBy<StorePerformance>(performanceList, ['datetime', 'show.name']),
  )

  if (_.values(PERFORMANCE_ROUTE_NAMES).includes(routeName)) {
    return route
  } else if (routeName === 'performanceDetail') {
    return { name: PERFORMANCE_ROUTE_NAMES.SCHEDULE, params: { performanceId } }
  } else if (routeName === 'reservationsPerformance') {
    return { name: PERFORMANCE_ROUTE_NAMES.RESERVATION_LIST, params: { performanceId } }
  } else if (routeName === 'reservationsPerformanceReservation') {
    return {
      name: PERFORMANCE_ROUTE_NAMES.RESERVATION_DETAIL,
      params: { performanceId, reservationId },
    }
  } else {
    return {
      name: PERFORMANCE_ROUTE_NAMES.SCHEDULE,
      params: { performanceId: performanceOfToday?.id || PERFORMANCE_PARAMS.EMPTY },
    }
  }
})
</script>
