import * as Sentry from '@sentry/vue'
import * as _ from 'lodash-es'
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  createRouter,
  createWebHistory,
} from 'vue-router'

import { ADD_ON_ROUTE_NAMES } from '@/modules/addon/constants/AddOnRouteNames'
import { FLOOR_PLAN_FLOW_ROUTES } from '@/modules/floorplan/constants/FloorPlanRouteNames'
import { onboardingRoutes } from '@/modules/onboarding/routes/OnboardingRoutes'
import {
  PERFORMANCE_FLOW_ROUTES,
  PERFORMANCE_RESERVATION_ROUTES,
} from '@/modules/performance/constants/PerformanceRouteConstants'
import { PROMO_CODE_ROUTE_NAMES } from '@/modules/promoCode/constants/PromoCodeRouteNames'
import { RESIDENCY_ROUTE_NAMES } from '@/modules/residency/constants/ResidencyRouteNames'
import { SETTING_ROUTE_NAMES } from '@/modules/setting/constants/SettingRouteNames'
import { SHOW_TIME_ROUTE_NAMES } from '@/modules/showtime/constants/ShowTimeRouteNames'
import { USER_ROUTE_NAMES } from '@/modules/user/constants/UserRouteNames'
import { guestRoutes } from '@/router/guest-routes'
import { venueRoutes } from '@/router/venue-routes'
import { useSettingStore } from '@/stores/setting'
import { useUserStore } from '@/stores/user'
import loc from '@/utils/loc'
import { gtmPageView } from '@/utils/utils'

import { UserGroupType } from '@generated/types'

export async function beforeEach(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const userStore = useUserStore()
  const requiredGroup = to.meta?.requiresAuth as UserGroupType | undefined

  const guestRouteNames = guestRoutes.map(route => route.name)
  const venueRouteNames = venueRoutes.map(route => route.name)
  if (
    venueRouteNames.includes(from.name as string) &&
    guestRouteNames.includes(to.name as string)
  ) {
    Sentry.captureException(
      new Error(
        `Loading guest bundle from venue bundle. From: ${from.name?.toString()}, To: ${to.name?.toString()}`,
      ),
    )
  }

  if (requiredGroup) {
    if (!userStore.isLoggedIn) {
      console.error(`Attempted to navigate to ${to.fullPath}, but not logged in`)
      loc.redirect(`/accounts/login/?next=${to.fullPath}`)
      return next(false)
    } else {
      await userStore.loading
      if (!userStore.hasGroup(requiredGroup)) {
        console.error(`Attempted to navigate to ${to.fullPath}, but not in ${requiredGroup}`)
        return next(new Error('Unauthorized'))
      }
    }
  }

  const settingStore = useSettingStore()
  if (settingStore.isSettingViewOnly && !to.fullPath.includes('/settings')) {
    return next({ name: SETTING_ROUTE_NAMES.FEES })
  }

  if (to.fullPath.startsWith('/#')) {
    return next(to.fullPath.slice(2))
  }
  return next()
}

export async function afterEach(to: RouteLocationNormalized) {
  gtmPageView(to)
  useUserStore().hotjarIdentification()
}

export function mkRouter() {
  const router = createRouter({
    routes: [...guestRoutes, ...venueRoutes, ...onboardingRoutes],
    history: createWebHistory(),
  })
  router.beforeEach(beforeEach)
  router.afterEach(afterEach)
  return router
}

export function isNavigatingAmongPerfFlow(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): boolean {
  if (!to.params.performanceId) return false
  return (
    _.values(PERFORMANCE_FLOW_ROUTES).includes(to.name) &&
    _.values(PERFORMANCE_FLOW_ROUTES).includes(from.name) &&
    to.params.performanceId === from.params.performanceId
  )
}

export function isNavigatingFromPerfResToPerfFlow(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): boolean {
  if (!to.params.performanceId) return false
  return (
    _.values(PERFORMANCE_FLOW_ROUTES).includes(to.name) &&
    _.values(PERFORMANCE_RESERVATION_ROUTES).includes(from.name) &&
    to.params.performanceId === from.params.performanceId
  )
}

export function isNavigationAmongFloorPlanEdit(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): boolean {
  if (!to.params.floorPlanId) return false
  return (
    _.values(FLOOR_PLAN_FLOW_ROUTES).includes(to.name) &&
    _.values(FLOOR_PLAN_FLOW_ROUTES).includes(from.name) &&
    to.params.floorPlanId === from.params.floorPlanId
  )
}

export function isAmongSettings(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): boolean {
  return (
    _.values(SETTING_ROUTE_NAMES).includes(to.name) &&
    _.values(SETTING_ROUTE_NAMES).includes(from.name)
  )
}

export function isWithinResidencyDetail(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): boolean {
  return (
    from.name === RESIDENCY_ROUTE_NAMES.DETAIL &&
    to.name === RESIDENCY_ROUTE_NAMES.DETAIL &&
    from.params.residencyId === to.params.residencyId
  )
}

export function isNavigatingAmongShowTimeFlow(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): boolean {
  return (
    _.values(SHOW_TIME_ROUTE_NAMES).includes(to.name) &&
    _.values(SHOW_TIME_ROUTE_NAMES).includes(from.name)
  )
}

export function isAmongUserRoutes(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): boolean {
  return (
    _.values(USER_ROUTE_NAMES).includes(to.name) && _.values(USER_ROUTE_NAMES).includes(from.name)
  )
}

export function isAmongAddOnRoutes(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): boolean {
  return (
    _.values(ADD_ON_ROUTE_NAMES).includes(to.name) &&
    _.values(ADD_ON_ROUTE_NAMES).includes(from.name)
  )
}

export function isAmongPromoCodeRoutes(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): boolean {
  return (
    _.values(PROMO_CODE_ROUTE_NAMES).includes(to.name) &&
    _.values(PROMO_CODE_ROUTE_NAMES).includes(from.name)
  )
}
