import { useEventBus } from '@vueuse/core'
import { defineStore } from 'pinia'

import * as api from '@/api/api'
import { guestKey } from '@/api/events'
import { gtmEvt } from '@/utils/utils'

import { GuestDetailedRead, GuestRead, GuestSearchRead } from '@generated/types'

interface GuestState {
  isLoading: boolean
  guest: GuestDetailedRead | null
  searchLoading: boolean
  searchResults: GuestSearchRead[]
}

export const useGuestStore = defineStore({
  id: 'guest',
  state: (): GuestState => ({
    isLoading: false,
    guest: null,
    searchLoading: false,
    searchResults: [],
  }),
  getters: {},
  actions: {
    async search(query: string): Promise<GuestSearchRead[]> {
      gtmEvt('guestSearch')
      this.searchLoading = true
      const results = await api.searchGuests({ query })
      this.searchLoading = false
      this.searchResults = results.data
      return results.data
    },
    async fetchGuest(id: number) {
      this.guest = null
      this.isLoading = true
      const response = await api.getGuest(id)
      this.isLoading = false
      this.guest = response.data
    },
    async updateGuest(guest: GuestRead) {
      gtmEvt('updateGuest')
      const response = await api.updateGuest(guest)
      useEventBus(guestKey).emit(response.data)
      return response.data
    },
    async mergeGuest(sourceId: number, targetId: number) {
      gtmEvt('mergeGuest')
      const response = await api.mergeGuest(sourceId, targetId)
      useEventBus(guestKey).emit(response.data)
      return response.data
    },
  },
})
