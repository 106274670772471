import { RouteRecordRaw } from 'vue-router'

import { ADD_ON_ROUTE_NAMES } from '@/modules/addon/constants/AddOnRouteNames'
import { FLOOR_PLAN_ROUTE_NAMES } from '@/modules/floorplan/constants/FloorPlanRouteNames'
import { MAILCHIMP_ROUTE_NAMES } from '@/modules/mailchimp/constants/MailchimpRouteNames'
import { PERFORMANCE_ROUTE_NAMES } from '@/modules/performance/constants/PerformanceRouteConstants'
import { PERFORMANCE_PARAMS } from '@/modules/performance/stores/PerformanceReservationStore'
import { PROMO_CODE_ROUTE_NAMES } from '@/modules/promoCode/constants/PromoCodeRouteNames'
import { RESIDENCY_ROUTE_NAMES } from '@/modules/residency/constants/ResidencyRouteNames'
import { SETTING_ROUTE_NAMES } from '@/modules/setting/constants/SettingRouteNames'
import { SHOW_ROUTE_NAMES } from '@/modules/show/constants/ShowRouteName'
import { SHOW_TIME_ROUTE_NAMES } from '@/modules/showtime/constants/ShowTimeRouteNames'
import { USER_ROUTE_NAMES } from '@/modules/user/constants/UserRouteNames'

import { UserGroup } from '@generated/types'

const PerformanceLayoutNew = () => import('@/modules/performance/layouts/PerformanceLayoutNew.vue')
const PerformanceAddOnEdit = () => import('@/modules/performance/pages/PerformanceAddOnEdit.vue')
const PerformanceAddOns = () => import('@/modules/performance/pages/PerformanceAddOns.vue')
const PerformanceDashboard = () => import('@/modules/performance/pages/PerformanceDashboard.vue')
const PerformancePricingTiers = () =>
  import('@/modules/performance/pages/PerformancePricingTiers.vue')
const PerformanceReservationDetail = () =>
  import('@/modules/performance/pages/PerformanceReservationDetail.vue')
const PerformanceReservationEdit = () =>
  import('@/modules/performance/pages/PerformanceReservationEdit.vue')
const PerformanceReservationList = () =>
  import('@/modules/performance/pages/PerformanceReservationList.vue')
const PerformanceWalkIn = () =>
  import('@/modules/performance/pages/PerformanceReservationWalkIn.vue')
const PerformanceSchedule = () => import('@/modules/performance/pages/PerformanceSchedule.vue')
const PerformanceSeatingTimes = () =>
  import('@/modules/performance/pages/PerformanceSeatingTimes.vue')
const PerformanceTableList = () => import('@/modules/performance/pages/PerformanceTableList.vue')
const PerformanceTableEdit = () => import('@/modules/performance/pages/PerformanceTableEdit.vue')
const AccountingSummary = () => import('@/pages/venue/AccountingSummary.vue')
const GuestDetail = () => import('@/pages/venue/GuestDetail.vue')
const ReportsOverview = () => import('@/pages/venue/ReportsOverview.vue')
const ReservationsDashboard = () => import('@/pages/venue/ReservationsDashboard.vue')
const StyleGuide = () => import('@/pages/venue/StyleGuide.vue')
const PerformanceAdd = () => import('@/modules/show/pages/ShowPerformanceAdd.vue')

export const venueRoutes: RouteRecordRaw[] = [
  {
    path: '/venue/playground',
    name: 'playground',
    component: () => import('@/modules/playground/pages/PlaygroundDefault.vue'),
    meta: {
      requiresAuth: UserGroup.MANAGERS,
      pageTitle: 'Floor Plans',
    },
  },
  {
    path: '/venue/floorplans',
    component: () => import('@/modules/floorplan/layouts/FloorPlanLayout.vue'),
    props: route => ({ floorPlanId: +route.params.floorPlanId || undefined }),
    meta: { requiresAuth: UserGroup.MANAGERS },
    children: [
      {
        path: '',
        name: FLOOR_PLAN_ROUTE_NAMES.LIST,
        component: () => import('@/modules/floorplan/pages/FloorPlanList.vue'),
        meta: { pageTitle: 'Floor Plans' },
      },
      {
        path: 'create',
        name: FLOOR_PLAN_ROUTE_NAMES.CREATE,
        component: () => import('@/modules/floorplan/pages/FloorPlanCreate.vue'),
        meta: { pageTitle: 'Floor Plan - Create' },
      },
      {
        path: ':floorPlanId/',
        children: [
          { path: '', redirect: { name: FLOOR_PLAN_ROUTE_NAMES.TABLE_LIST } },
          {
            path: 'tables',
            children: [
              {
                path: '',
                name: FLOOR_PLAN_ROUTE_NAMES.TABLE_LIST,
                component: () => import('@/modules/floorplan/pages/FloorPlanTableList.vue'),
                props: route => ({ floorPlanId: +route.params.floorPlanId }),
                meta: { pageTitle: 'Floor Plan - Tables' },
              },
              {
                path: ':tableIds+',
                name: FLOOR_PLAN_ROUTE_NAMES.TABLE_EDIT,
                component: () => import('@/modules/floorplan/pages/FloorPlanTableEdit.vue'),
                props: route => {
                  const tableIds: Array<number | string> = (route.params.tableIds as string[]).map(
                    id => (isNaN(Number(id)) ? id : Number(id)),
                  )
                  return { tableIds }
                },
                meta: { pageTitle: 'Floor Plan - Table Detail' },
              },
            ],
          },
          {
            path: 'pricing-tiers',
            name: FLOOR_PLAN_ROUTE_NAMES.PRICING_TIERS,
            component: () => import('@/modules/floorplan/pages/FloorPlanPricingTiers.vue'),
            meta: { pageTitle: 'Floor Plan - Pricing Tiers' },
          },
        ],
      },
    ],
  },
  {
    path: '/venue/showtimes',
    name: 'showtimes',
    redirect: { name: SHOW_TIME_ROUTE_NAMES.LIST },
  },
  {
    path: '/venue/show-time',
    component: () => import('@/modules/showtime/layouts/ShowTimeLayout.vue'),
    props: route => {
      const showTimeId = route.params.showTimeId
      if (!showTimeId) return { showTimeId: undefined }
      return { showTimeId: isNaN(Number(showTimeId)) ? showTimeId : Number(showTimeId) }
    },
    name: SHOW_TIME_ROUTE_NAMES.LIST,
    meta: { requiresAuth: UserGroup.MANAGERS, pageTitle: 'Show Times' },
    children: [
      {
        path: ':showTimeId/edit',
        name: SHOW_TIME_ROUTE_NAMES.EDIT,
        component: () => import('@/modules/showtime/pages/ShowTimeEdit.vue'),
        props: route => {
          const showTimeId = route.params.showTimeId
          return { showTimeId: isNaN(Number(showTimeId)) ? showTimeId : Number(showTimeId) }
        },
        meta: {
          requiresAuth: UserGroup.MANAGERS,
          pageTitle: 'Show Time Edit',
        },
      },
    ],
  },
  {
    path: '/venue/shows',
    name: 'showList',
    redirect: { name: SHOW_ROUTE_NAMES.LIST },
    meta: {
      requiresAuth: UserGroup.MANAGERS,
      pageTitle: 'Show List',
    },
  },
  {
    path: '/venue/shows/add',
    name: 'showAdd',
    redirect: { name: SHOW_ROUTE_NAMES.CREATE },
    meta: {
      requiresAuth: UserGroup.MANAGERS,
      pageTitle: 'Add Show',
    },
  },
  {
    path: '/venue/shows/:id',
    name: 'showDetail',
    redirect(to) {
      return { name: SHOW_ROUTE_NAMES.DETAIL, params: { showId: +to.params.id } }
    },
    meta: {
      requiresAuth: UserGroup.MANAGERS,
      pageTitle: 'Show Detail',
    },
  },
  {
    path: '/venue/shows/:id/edit',
    name: 'showEdit',
    redirect(to) {
      return { name: SHOW_ROUTE_NAMES.EDIT, params: { showId: +to.params.id } }
    },
    meta: {
      requiresAuth: UserGroup.MANAGERS,
      pageTitle: 'Edit Show',
    },
  },
  {
    path: '/venue/shows/:showId/performance/add',
    name: 'performanceAdd',
    component: PerformanceAdd,
    meta: {
      requiresAuth: UserGroup.MANAGERS,
      pageTitle: 'Add Performance',
    },
  },
  {
    path: '/venue/show/',
    component: () => import('@/modules/show/layouts/ShowLayout.vue'),
    props: route => ({ showId: +route.params.showId || undefined }),
    children: [
      {
        path: '',
        name: SHOW_ROUTE_NAMES.LIST,
        component: () => import('@/modules/show/pages/ShowList.vue'),
        meta: {
          requiresAuth: UserGroup.MANAGERS,
          pageTitle: 'Show List',
        },
      },
      {
        path: 'create',
        name: SHOW_ROUTE_NAMES.CREATE,
        component: () => import('@/modules/show/pages/ShowCreate.vue'),
        meta: {
          requiresAuth: UserGroup.MANAGERS,
          pageTitle: 'Show Create',
        },
      },
      {
        path: ':showId',
        name: SHOW_ROUTE_NAMES.DETAIL,
        component: () => import('@/modules/show/pages/ShowDetail.vue'),
        props: route => ({ showId: +route.params.showId }),
        meta: {
          requiresAuth: UserGroup.MANAGERS,
          pageTitle: 'Show Detail',
        },
      },
      {
        path: ':showId/edit',
        name: SHOW_ROUTE_NAMES.EDIT,
        component: () => import('@/modules/show/pages/ShowEdit.vue'),
        props: route => ({ showId: +route.params.showId }),
        meta: {
          requiresAuth: UserGroup.MANAGERS,
          pageTitle: 'Show Edit',
        },
      },
    ],
  },
  {
    path: '/venue/performance/:performanceId',
    name: 'performanceDetail',
    redirect: { name: PERFORMANCE_ROUTE_NAMES.DASHBOARD },
  },
  {
    path: '/venue/performance-new/:performanceId',
    component: PerformanceLayoutNew,
    props: route => ({ performanceId: +route.params.performanceId }),
    children: [
      { path: '', redirect: { name: PERFORMANCE_ROUTE_NAMES.SCHEDULE } },
      {
        path: 'schedule/',
        name: PERFORMANCE_ROUTE_NAMES.SCHEDULE,
        component: PerformanceSchedule,
        meta: { pageTitle: 'Performance - Schedule' },
      },
      {
        path: 'tables/',
        children: [
          {
            path: '',
            name: PERFORMANCE_ROUTE_NAMES.TABLES,
            component: PerformanceTableList,
            meta: { pageTitle: 'Performance - Table List' },
          },
          {
            path: ':tableIds+',
            name: PERFORMANCE_ROUTE_NAMES.TABLE_EDIT,
            component: PerformanceTableEdit,
            props: route => {
              const tableIds: Array<number | string> = (route.params.tableIds as string[]).map(
                id => (isNaN(Number(id)) ? id : Number(id)),
              )
              return { tableIds }
            },
            meta: { pageTitle: 'Performance - Table Detail' },
          },
        ],
      },
      {
        path: 'seating-times/',
        name: PERFORMANCE_ROUTE_NAMES.SEATING_TIMES,
        component: PerformanceSeatingTimes,
        meta: { pageTitle: 'Performance - Seating Times' },
      },
      {
        path: 'add-ons/',
        children: [
          {
            path: '',
            name: PERFORMANCE_ROUTE_NAMES.ADD_ONS,
            component: PerformanceAddOns,
            meta: { pageTitle: 'Performance - Add-ons' },
          },
          {
            path: ':addOnId',
            name: PERFORMANCE_ROUTE_NAMES.ADD_ON_EDIT,
            component: PerformanceAddOnEdit,
            props: route => ({
              addOnId: Number(route.params.addOnId),
            }),
            meta: { pageTitle: 'Performance - Add-on Detail' },
          },
        ],
      },
      {
        path: 'pricing-tiers/',
        name: PERFORMANCE_ROUTE_NAMES.PRICING_TIERS,
        component: PerformancePricingTiers,
        meta: { pageTitle: 'Performance - Pricing Tiers' },
      },
      {
        path: 'dashboard/',
        name: PERFORMANCE_ROUTE_NAMES.DASHBOARD,
        component: PerformanceDashboard,
        meta: { pageTitle: 'Performance - Dashboard' },
      },
      {
        path: 'livestream',
        name: PERFORMANCE_ROUTE_NAMES.LIVESTREAM,
        component: () => import('@/modules/performance/pages/PerformanceLivestream.vue'),
        meta: { pageTitle: 'Performance - Livestream' },
      },
      {
        path: 'miscellaneous',
        name: PERFORMANCE_ROUTE_NAMES.MISCELLANEOUS,
        component: () => import('@/modules/performance/pages/PerformanceMiscellaneous.vue'),
        meta: { pageTitle: 'Performance - Miscellaneous' },
      },
      {
        path: 'reservations/',
        children: [
          {
            path: '',
            name: PERFORMANCE_ROUTE_NAMES.RESERVATION_LIST,
            component: PerformanceReservationList,
            meta: { pageTitle: 'Performance - Reservation List' },
          },
          {
            path: 'walk-in',
            name: PERFORMANCE_ROUTE_NAMES.RESERVATION_WALK_IN,
            component: PerformanceWalkIn,
            meta: { pageTitle: 'Performance - Reservation Walk-in' },
          },
          {
            path: 'add-ons',
            name: PERFORMANCE_ROUTE_NAMES.RESERVATION_ADD_ON_LIST,
            component: () =>
              import('@/modules/performance/pages/PerformanceReservationAddOnList.vue'),
            meta: { pageTitle: 'Performance - Reservation Add-ons' },
          },
          {
            path: ':reservationId',
            children: [
              {
                path: '',
                name: PERFORMANCE_ROUTE_NAMES.RESERVATION_DETAIL,
                component: PerformanceReservationDetail,
                props: route => ({
                  reservationId: +route.params.reservationId,
                  performanceId: +route.params.performanceId,
                }),
                meta: { pageTitle: 'Performance - Reservation Detail' },
              },
              {
                path: 'edit',
                name: PERFORMANCE_ROUTE_NAMES.RESERVATION_EDIT,
                component: PerformanceReservationEdit,
                props: route => ({
                  reservationId: +route.params.reservationId,
                  performanceId: +route.params.performanceId,
                }),
                meta: { pageTitle: 'Performance - Reservation Edit' },
              },
              {
                path: 'refund',
                name: PERFORMANCE_ROUTE_NAMES.RESERVATION_REFUND,
                component: () =>
                  import('@/modules/performance/pages/PerformanceReservationRefund.vue'),
                props: route => ({
                  reservationId: +route.params.reservationId,
                  performanceId: +route.params.performanceId,
                }),
                meta: { pageTitle: 'Performance - Reservation Refund' },
              },
            ],
          },
        ],
      },
    ],
    meta: {
      requiresAuth: UserGroup.MANAGERS,
      pageTitle: 'Performance',
    },
  },
  {
    path: '/venue/performance-new/:performanceId/print-reservations',
    name: PERFORMANCE_ROUTE_NAMES.PRINT_RESERVATIONS,
    component: () => import('@/modules/performance/pages/PerformancePrintReservations.vue'),
    props: route => ({ performanceId: +route.params.performanceId }),
    meta: { pageTitle: 'Performance - Print Reservations' },
  },
  {
    path: '/venue/performance-new/:performanceId/reservations/:reservationId/move-to-diff-perf',
    name: PERFORMANCE_ROUTE_NAMES.RESERVATION_MOVE_DETAIL,
    component: () => import('@/modules/performance/pages/PerformanceMoveReservation.vue'),
    props: route => ({
      reservationId: +route.params.reservationId,
      performanceId: +route.params.performanceId,
    }),
    meta: { pageTitle: 'Moving Reservation' },
  },
  {
    path: '/venue/reservations/performance/:performanceId',
    name: 'reservationsPerformance',
    redirect: { name: PERFORMANCE_ROUTE_NAMES.RESERVATION_LIST },
  },
  {
    path: '/venue/reservations',
    name: 'reservations',
    redirect: {
      name: PERFORMANCE_ROUTE_NAMES.RESERVATION_LIST,
      params: { performanceId: PERFORMANCE_PARAMS.EMPTY },
    },
  },
  {
    path: '/venue/dashboard',
    name: 'reservationsDashboard',
    component: ReservationsDashboard,
    meta: {
      requiresAuth: UserGroup.MANAGERS,
      pageTitle: 'Dashboard',
    },
  },
  {
    path: '/venue/reports/overview',
    name: 'reportsOverview',
    // @ts-ignore
    component: ReportsOverview,
    meta: {
      requiresAuth: UserGroup.OWNERS,
      pageTitle: 'Reports Overview',
    },
  },
  {
    path: '/venue/accounting-summary',
    name: 'accountingSummary',
    component: AccountingSummary,
    meta: {
      requiresAuth: UserGroup.OWNERS,
      pageTitle: 'Accounting Summary',
    },
  },
  {
    path: '/venue/guest/:id',
    name: 'guest',
    component: GuestDetail,
    meta: {
      requiresAuth: UserGroup.MANAGERS,
      pageTitle: 'Guest Detail',
    },
  },
  {
    path: '/venue/style-guide',
    name: 'styleguide',
    component: StyleGuide,
    meta: {
      requiresAuth: UserGroup.MANAGERS,
      pageTitle: 'Style Guide',
    },
  },
  {
    path: '/venue/settings',
    redirect() {
      return { name: SETTING_ROUTE_NAMES.FEES }
    },
    component: () => import('@/modules/setting/layouts/SettingLayout.vue'),
    children: [
      {
        path: 'fees',
        name: SETTING_ROUTE_NAMES.FEES,
        component: () => import('@/modules/setting/pages/SettingExposeFee.vue'),
      },
      {
        path: 'taxes',
        name: SETTING_ROUTE_NAMES.TAXES,
        component: () => import('@/modules/setting/pages/SettingTaxes.vue'),
      },
      {
        path: 'misc',
        name: SETTING_ROUTE_NAMES.MISC,
        component: () => import('@/modules/setting/pages/SettingMisc.vue'),
      },
      {
        path: 'checkout',
        name: SETTING_ROUTE_NAMES.CHECKOUT,
        component: () => import('@/modules/setting/pages/SettingCheckout.vue'),
      },
      {
        path: 'dashboard',
        name: SETTING_ROUTE_NAMES.SETUP,
        component: () => import('@/modules/setting/pages/SettingDashboard.vue'),
      },
      {
        path: 'integrations',
        children: [
          {
            path: 'mailchimp',
            name: SETTING_ROUTE_NAMES.MAILCHIMP,
            component: () => import('@/modules/setting/pages/SettingMailchimp.vue'),
          },
        ],
      },
      {
        path: 'add-on',
        name: ADD_ON_ROUTE_NAMES.ADD_ON_LIST,
        component: () => import('@/modules/addon/layouts/AddOnLayout.vue'),
        props: route => {
          const addOnId = route.params.addOnId
          if (!addOnId) return { addOnId: undefined }
          return { addOnId: isNaN(Number(addOnId)) ? addOnId : Number(addOnId) }
        },
        children: [
          {
            path: ':addOnId/edit',
            name: ADD_ON_ROUTE_NAMES.ADD_ON_EDIT,
            component: () => import('@/modules/addon/pages/AddOnEdit.vue'),
            props: route => {
              const addOnId = route.params.addOnId
              return { addOnId: isNaN(Number(addOnId)) ? addOnId : Number(addOnId) }
            },
          },
        ],
      },
      {
        path: 'promo-codes',
        name: PROMO_CODE_ROUTE_NAMES.PROMO_CODE_LIST,
        component: () => import('@/modules/promoCode/layouts/PromoCodeLayout.vue'),
        props: route => {
          const promoCodeId = route.params.promoCodeId
          if (!promoCodeId) return { promoCodeId: undefined }
          return { promoCodeId: isNaN(Number(promoCodeId)) ? promoCodeId : Number(promoCodeId) }
        },
        children: [
          {
            path: ':promoCodeId/edit',
            name: PROMO_CODE_ROUTE_NAMES.PROMO_CODE_EDIT,
            component: () => import('@/modules/promoCode/pages/PromoCodeEdit.vue'),
            props: route => {
              const promoCodeId = route.params.promoCodeId
              return { promoCodeId: isNaN(Number(promoCodeId)) ? promoCodeId : Number(promoCodeId) }
            },
          },
        ],
      },
      {
        path: 'user',
        name: USER_ROUTE_NAMES.USER_LIST,
        component: () => import('@/modules/user/layouts/UserLayout.vue'),
        props: route => {
          const userId = route.params.userId
          if (!userId) return { userId: undefined }
          return { userId: isNaN(Number(userId)) ? userId : Number(userId) }
        },
        children: [
          {
            path: ':userId/edit',
            name: USER_ROUTE_NAMES.USER_EDIT,
            component: () => import('@/modules/user/pages/UserEdit.vue'),
            props: route => {
              const userId = route.params.userId
              return { userId: isNaN(Number(userId)) ? userId : Number(userId) }
            },
          },
        ],
      },
      {
        path: 'payments',
        name: SETTING_ROUTE_NAMES.PAYMENTS,
        component: () => import('@/modules/setting/pages/SettingPayments.vue'),
      },
    ],
    meta: {
      requiresAuth: UserGroup.OWNERS,
      pageTitle: 'Settings',
    },
  },
  {
    path: '/venue/marketing',
    meta: {
      requiresAuth: UserGroup.OWNERS,
      pageTitle: 'Marketing',
    },
    children: [
      {
        path: 'mailchimp',
        name: MAILCHIMP_ROUTE_NAMES.TEMPLATE_EDIT,
        component: () => import('@/modules/mailchimp/pages/MailchimpTemplateEdit.vue'),
      },
    ],
  },
  {
    path: '/venue/residency',
    name: RESIDENCY_ROUTE_NAMES.LIST,
    component: () => import('@/modules/residency/pages/ResidencyList.vue'),
    meta: {
      requiresAuth: UserGroup.MANAGERS,
      pageTitle: 'Residency',
    },
  },
  {
    path: '/venue/residency/:residencyId',
    name: RESIDENCY_ROUTE_NAMES.DETAIL,
    component: () => import('@/modules/residency/pages/ResidencyDetail.vue'),
    props: route => {
      const residencyId = route.params.residencyId
      return { residencyId: isNaN(Number(residencyId)) ? residencyId : Number(residencyId) }
    },
    meta: {
      requiresAuth: UserGroup.MANAGERS,
      pageTitle: 'Residency Detail',
    },
  },
]
