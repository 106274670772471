import { cloneDeep } from 'lodash-es'
import moment from 'moment/moment'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import * as api from '@/api/api'
import { StorePerformance, parsePerformance } from '@/models'
import { sortBy, upsertById } from '@/utils/utils'

import { PerformanceId, PerformanceListRead, PerformanceQueryParams } from '@generated/types'

export const usePerformancePaginate = defineStore('performancePaginate', () => {
  const performances = ref<StorePerformance[]>([])
  if (typeof window === 'object' && window?.__tt_preload?.pagination) {
    performances.value = window.__tt_preload.pagination
  }

  function storePerformances(performanceList: PerformanceListRead[]) {
    for (const performance of performanceList) {
      upsertById(parsePerformance(performance), performances.value)
    }
  }

  async function getPerformanceWithInvite(performanceId: PerformanceId, inviteCode: string) {
    const response = await api.getPerformance(performanceId, { invite_code: inviteCode })
    storePerformances([response.data])
  }

  async function getPerformances(query: PerformanceQueryParams) {
    const response = await api.getPerformances(query || {})
    storePerformances(response.data.results)
  }

  const isLoading = ref<boolean>(false)
  const hasMore = ref<boolean>(true)
  const maxPerformanceDate = computed<string | undefined>(
    () => sortBy(cloneDeep(performances.value), 'date').pop()?.date,
  )
  async function initializePagination() {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD')
    await getPerformances({
      booking: 'true',
      pagination: 'true',
      start_date: startOfMonth,
      end_date: endOfMonth,
    })
  }
  async function loadNextMonthOfPerformances() {
    if (isLoading.value) return
    isLoading.value = true
    const endOfNextMonth = moment(maxPerformanceDate.value)
      .add(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD')
    const response = await api.getPerformances({
      booking: 'true',
      pagination: 'true',
      start_date: maxPerformanceDate.value || moment().format('YYYY-MM-DD'),
      end_date: endOfNextMonth,
    })
    const performanceLengthBeforeUpdate = performances.value.length
    storePerformances([...performances.value, ...response.data.results])
    const newPerformanceLength = performances.value.length
    // If the length of the performances array has not changed, it means that there are no more performances to load
    hasMore.value = performanceLengthBeforeUpdate !== newPerformanceLength
    isLoading.value = false
  }
  async function intersectionObserver([entry]: IntersectionObserverEntry[]) {
    if (entry.isIntersecting && hasMore.value) await loadNextMonthOfPerformances()
  }

  return {
    performances,
    getPerformances,
    getPerformanceWithInvite,
    initializePagination,
    intersectionObserver,
    hasMore,
  }
})
