<template>
  <div>
    <input
      v-model="query"
      class="placeholder:text-fg-placeholder focus:outline-none focus:border-heading focus:ring-heading focus:ring-2 block bg-surface-primary w-48 border border-solid border-linear-subtle rounded text-sm px-2 py-1"
      placeholder="Search"
      type="text"
      id="search-input"
      name="search"
    />
  </div>
</template>
<style scoped></style>
<script lang="ts">
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

import { useGuestStore } from '@/stores/guest'

import { GuestSearchRead } from '@generated/types'

export default defineComponent({
  name: 'SearchInput',
  data() {
    return {
      query: '',
      results: [] as GuestSearchRead[],
    }
  },
  computed: {
    ...mapStores(useGuestStore),
  },
  methods: {
    clear() {
      this.query = ''
      this.results = []
    },
  },
  watch: {
    async query(newQuery) {
      if (newQuery.length > 2) {
        this.results = await this.guestStore.search(newQuery)
      } else {
        this.results = []
        this.guestStore.searchResults = []
      }
    },
  },
})
</script>
