<template>
  <div v-if="canUseVenueMenu">
    <Button id="trigger" @click="popoverRef?.toggle($event)" class="!p-0" link>
      <span>{{ venue_name }} </span>
      <ChevronDownIcon class="stroke-link-primary w-4 h-4" />
    </Button>
    <Popover
      ref="popoverRef"
      pt:content:class="flex flex-col !p-0 max-h-[80vh] overflow-y-auto tt-scroll"
    >
      <a
        v-for="venue in venues"
        :key="venue.primary_site"
        :href="'https://' + venue.primary_site + targetPath"
        class="venue-link py-2 px-4 transition hover:bg-surface-base"
        :class="
          location.origin?.includes(venue.primary_site)
            ? '!text-fg-primary font-semibold'
            : '!text-fg-link-primary'
        "
      >
        {{ venue.name }}
      </a>
    </Popover>
  </div>
  <p v-else id="single-venue-name" class="text-base font-sans flex items-center justify-center">
    {{ venue_name }}
  </p>
</template>

<script setup lang="ts">
import { useBrowserLocation } from '@vueuse/core'
import { sortBy } from 'lodash-es'
import { storeToRefs } from 'pinia'
import Button from 'primevue/button'
import Popover from 'primevue/popover'
import { computed, ref } from 'vue'

import ChevronDownIcon from '@/assets/chevronDown.svg?component'
import { useSettingStore } from '@/stores/setting'
import { useUserStore } from '@/stores/user'

import type { UserVenueRead } from '@generated/types'

const popoverRef = ref<InstanceType<typeof Popover> | null>(null)

const settingStore = useSettingStore()
const { venue_name } = storeToRefs(settingStore)

const userStore = useUserStore()
const { me, isSuperuser } = storeToRefs(userStore)
const venues = computed<UserVenueRead[]>(() => sortBy(me.value.venues, 'name'))

const location = useBrowserLocation()

const canUseVenueMenu = computed<boolean>(() => {
  return venues.value.length > 1
})

const targetPath = computed<string>(() => {
  return isSuperuser.value ? '/slogin/' : '/venue/reservations/'
})
</script>
