<template>
  <div
    class="message-alert transform p-5 rounded-md bg-surface-primary shadow-podHover max-w-4xl fixed bottom-5 z-10 border border-linear-subtle ml-2 transition-all duration-200 ease-out"
    :hidden="!show"
    :class="[show ? 'translate-x-0 left-2' : '-translate-x-full -left-10']"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MessageAlert',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
