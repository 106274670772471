import { RouteRecordRaw } from 'vue-router'

import { ONBOARDING_ROUTE_NAMES } from '@/modules/onboarding/constants/OnboardingRouteNames'

export const onboardingRoutes: RouteRecordRaw[] = [
  {
    path: '/venue/onboarding',
    name: 'onboarding',
    component: () => import('@/modules/onboarding/layouts/OnboardingLayout.vue'),
    children: [
      {
        path: ':userId(\\d+)/:token',
        name: ONBOARDING_ROUTE_NAMES.ENTRY,
        component: () => import('@/modules/onboarding/pages/OnboardingEntry.vue'),
        props(route) {
          return { ...route.params, userId: +route.params.userId }
        },
      },
      {
        path: 'password',
        name: ONBOARDING_ROUTE_NAMES.PASSWORD,
        component: () => import('@/modules/onboarding/pages/OnboardingPassword.vue'),
      },
      {
        path: 'done',
        name: ONBOARDING_ROUTE_NAMES.DONE,
        component: () => import('@/modules/onboarding/pages/OnboardingDone.vue'),
      },
      {
        path: 'expired',
        name: ONBOARDING_ROUTE_NAMES.TOKEN_EXPIRED,
        component: () => import('@/modules/onboarding/pages/OnboardingTokenExpired.vue'),
      },
    ],
    meta: {
      onboarding: true,
      pageTitle: 'Setup',
    },
  },
]
