/// <reference types="vite/client" />
import '../tailwind/input.css'
import { RouterPlugin } from './router/RouterPlugin'
import { useSettingStore } from './stores/setting'
import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'
import 'external-svg-loader'
import { createPinia, setActivePinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import { createApp } from 'vue'

import AppVenue from '@/AppVenue.vue'
import { configureBrowserAxios } from '@/api/api'
import { mkRouter } from '@/router'
import { mkBrowserSentry } from '@/sentry'

configureBrowserAxios()

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const router = mkRouter()

pinia.use(RouterPlugin(router))
setActivePinia(pinia)

const app = createApp(AppVenue)
app.use(pinia)
app.use(router)
app.use(ToastService)

const ttPreset = definePreset(Aura, {
  primitive: {
    tt: {
      50: '#effafc',
      100: '#d6f1f7',
      200: '#b2e3ef',
      300: '#7ecee2',
      400: '#42b0ce',
      500: '#2694b5',
      600: '#227798',
      700: '#22617c',
      800: '#245066',
      900: '#224457',
      950: '#112b3b',
    },
  },
  semantic: {
    primary: {
      50: '{tt.50}',
      100: '{tt.100}',
      200: '{tt.200}',
      300: '{tt.300}',
      400: '{tt.400}',
      500: '{tt.500}',
      600: '{tt.600}',
      700: '{tt.700}',
      800: '{tt.800}',
      900: '{tt.900}',
      950: '{tt.950}',
    },
  },
})
app.use(PrimeVue, {
  theme: {
    preset: ttPreset,
    options: {
      darkModeSelector: '.dark',
    },
  },
})

if (import.meta.env.VUE_APP_VERSION) {
  mkBrowserSentry(app, router)
}
useSettingStore().fetch()
router.isReady().then(() => app.mount('#app'))
