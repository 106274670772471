<template>
  <div class="flex items-center justify-between text-sm">
    <button
      id="light-mode-button"
      class="flex items-center justify-start gap-1"
      :class="store === 'light' ? 'text-fg-primary' : 'text-fg-link-primary'"
      @click="switchMode('light')"
    >
      <SunIcon class="w-4 h-4" />
      <span>Light</span>
    </button>
    <button
      id="dark-mode-button"
      class="flex items-center justify-start gap-1"
      :class="store === 'dark' ? 'text-fg-primary' : 'text-fg-link-primary'"
      @click="switchMode('dark')"
    >
      <MoonIcon class="w-4 h-4" />
      <span>Dark</span>
    </button>
    <button
      id="auto-mode-button"
      class="flex items-center justify-start gap-1"
      :class="store === 'auto' ? 'text-fg-primary' : 'text-fg-link-primary'"
      @click="switchMode('auto')"
    >
      <ComputerDesktopIcon class="w-4 h-4" />
      <span>Auto</span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { BasicColorSchema, useColorMode } from '@vueuse/core'

import ComputerDesktopIcon from '@/assets/computerDesktop.svg?component'
import MoonIcon from '@/assets/moon.svg?component'
import SunIcon from '@/assets/sun.svg?component'

const colorMode = useColorMode()
const { store } = colorMode

function switchMode(mode: BasicColorSchema) {
  colorMode.value = mode
}
</script>
