import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "8",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "4",
      cy: "4",
      r: "4",
      class: "fill-fg-secondary"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "16",
      cy: "4",
      r: "4",
      class: "fill-fg-tertiary"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "28",
      cy: "4",
      r: "4",
      class: "fill-fg-placeholder"
    }, null, -1)
  ])))
}
export default { render: render }