<template>
  <section class="w-full lg:container lg:mx-auto">
    <PerformanceList :performances="performances" />
    <div ref="loader">
      <Spinner class="!h-24" :display="hasMore" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core'
import moment from 'moment'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onServerPrefetch, ref } from 'vue'

import Spinner from '@/common/components/atoms/Spinner.vue'
import { StorePerformance } from '@/models'
import PerformanceList from '@/modules/guestUi/components/organisms/PerformanceList.vue'
import { usePerformancePaginate } from '@/modules/guestUi/stores/PerformancePaginateStore'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'

const settingStore = useSettingStore()
onServerPrefetch(async () => {
  settingStore.ssrTitle = `Shows - ${settingStore.venue_name}`
  settingStore.ssrDescription = `Purchase tickets for upcoming shows at ${settingStore.venue_name}.`
})

const checkoutStore = useCheckoutStore()
onMounted(async () => {
  await checkoutStore.abandonAnonymousReservation()
  checkoutStore.clearAll()
  scrollToShow()
})
function scrollToShow() {
  if (!checkoutStore.scrollPositionFromSetShow) return
  window.scrollTo({ top: checkoutStore.scrollPositionFromSetShow - 100 })
  checkoutStore.setScrollPositionFromSetShow(null)
}

const performancePaginateStore = usePerformancePaginate()
const { hasMore } = storeToRefs(performancePaginateStore)
const performances = computed<StorePerformance[]>(() =>
  performancePaginateStore.performances.filter(p =>
    p.publish_datetime === null ? true : moment(p.publish_datetime).isSameOrBefore(moment()),
  ),
)

const loader = ref<HTMLDivElement | null>(null)
useIntersectionObserver(loader, performancePaginateStore.intersectionObserver)
</script>
