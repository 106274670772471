export const FLOOR_PLAN_FLOW_ROUTES = {
  TABLE_LIST: 'floorPlanTableList',
  TABLE_EDIT: 'floorPlanTableEdit',
  PRICING_TIERS: 'floorPlanPricingTiers',
}

export const FLOOR_PLAN_ROUTE_NAMES = {
  LIST: 'floorPlanList',
  CREATE: 'floorPlanCreate',
  ...FLOOR_PLAN_FLOW_ROUTES,
}
